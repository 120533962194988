import type { PublicEnvs } from '~/constants/env.server';


/**
 * Safely access public environment variables on window.ENV, with a fallback to process.env.
 *
 * Since window.ENV is populated from the parsed process.env, any public variable will be available
 * in both places.
 */
export const publicEnv = (): PublicEnvs => {
  return typeof window !== 'undefined' ? window.ENV : process.env;
};
